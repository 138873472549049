@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  margin: 0;
}
body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
}
#root {
  min-height: 100vh; /* Ensure the root covers at least the viewport height */
  overflow-y: scroll; /* Allow vertical scrolling */
}

.section {
  width: 91.6666666%; /* 10/12 of the width */
  margin-left: auto;
  margin-right: auto;
  padding-top: 8em;
}

@media (min-width: 1024px) {
  .section {
    width: 83.333333%; 
    margin-bottom: 0;
  }
}
